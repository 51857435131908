import * as React from 'react';
import './App.css';
import { Typography,Grid,Card,Box} from '@mui/material';
import { useEffect, useState,createContext } from 'react';
import pidiLogo from './pidi.png'
import pidiLogowhite from './pidiwhite.png'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ResponsiveBar } from '@nivo/bar'
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import ReactSwitch from "react-switch";
import axios from "axios";


export const ThemeContext = createContext(null);


// Drawer
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));
// Drawer


const CustomCard = styled(Card)(({ theme }) => ({
  borderRadius: 25,
}));

const MyResponsiveBar = ({ data /* see data tab */ }) => (
  <ResponsiveBar
      groupMode='grouped'
      data={data}
      keys={[
          'Target',
          'Actual'
      ]}
      theme={{
        "background": "transparent",
        "textColor": "#333333",
        "fontSize": 11,
        "axis": {
            "domain": {
                "line": {
                    "stroke": "#777777",
                    "strokeWidth": 1
                }
            },
            "legend": {
                "text": {
                    "fontSize": 12,
                    "fill": "#333333"
                }
            },
            "ticks": {
                "line": {
                    "stroke": "#777777",
                    "strokeWidth": 1
                },
                "text": {
                    "fontSize": 11,
                    "fill": "#333333"
                }
            }
        },
        "legends": {
            "title": {
                "text": {
                    "fontSize": 11,
                    "fill": "#333333"
                }
            },
            "text": {
                "fontSize": 11,
                "fill": "#333333"
            },
            "ticks": {
                "line": {},
                "text": {
                    "fontSize": 10,
                    "fill": "#333333"
                }
            }
        },
        "annotations": {
            "text": {
                "fontSize": 13,
                "fill": "#333333",
                "outlineWidth": 2,
                "outlineColor": "#ffffff",
                "outlineOpacity": 1
            },
            "link": {
                "stroke": "#000000",
                "strokeWidth": 1,
                "outlineWidth": 2,
                "outlineColor": "#ffffff",
                "outlineOpacity": 1
            },
            "outline": {
                "stroke": "#000000",
                "strokeWidth": 2,
                "outlineWidth": 2,
                "outlineColor": "#ffffff",
                "outlineOpacity": 1
            },
            "symbol": {
                "fill": "#000000",
                "outlineWidth": 2,
                "outlineColor": "#ffffff",
                "outlineOpacity": 1
            }
        },
        "tooltip": {
            "container": {
                "background": "#ffffff",
                "color": "#333333",
                "fontSize": 12
            },
            "basic": {},
            "chip": {},
            "table": {},
            "tableCell": {},
            "tableCellValue": {}
        }
      }}
      indexBy="date"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      defs={[
          {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true
          },
          {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
          }
      ]}
      fill={[
          {
              match: {
                  id: 'fries'
              },
              id: 'dots'
          },
          {
              match: {
                  id: 'sandwich'
              },
              id: 'lines'
          }
      ]}
      borderColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  1.6
              ]
          ]
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Date',
          legendPosition: 'middle',
          legendOffset: 32
      }}
      axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'OEE (%)',
          legendPosition: 'middle',
          legendOffset: -40
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  1.6
              ]
          ]
      }}
      legends={[
          {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemOpacity: 1
                      }
                  }
              ]
          }
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
  />
)

function App() {

  //Thema
  const [themes, setThemes] = useState("dark");
  const toggleTheme = () => {
    setThemes((curr) => (curr === "light" ? "dark" : "light"));
  };

  // Drawer
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // Drawer


  const effieciencyRandomCok = (actual,target) =>{
    var randomMinus = Math.round(Math.random()*(0.01*target))
    var randomEff = Math.floor(((actual-randomMinus)/target)*100)
    var actualRandom = actual-randomMinus
    //console.log({randomEff})
    return {randomEff,actualRandom}
  }

  const gradientString = 'radial-gradient(farthest-corner at 10px 10px,#DDDDDD 50%,#B7C4CF 100%)'
  const [currentTime,setCurrentTime] = useState('')
  const [actualProductionData,setActualProductionData] = useState(100)
  const [targetProductionData,settargetProductionData] = useState(100)
const efficiencyThreshold = 96
  var tempArray = []
  for (let index = 1; index < 30; index++) {
    tempArray.push({
      x:index,
      y:100-Math.random()*5
    })} 
  
var tempArrayTarget = []
for (let index = 1; index < 30; index++) {
  tempArrayTarget.push({
    x:index,
    y:97
  })}
  const [currentDay,setCurrentDay] = useState('')
  const daysString = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
  useEffect(()=>{
    var sakniki = new Date()
    var isukMau = new Date()
    isukMau.setHours(0)
    isukMau.setMinutes(0)
    isukMau.setSeconds(0)
    isukMau.setMilliseconds(0)
    var selisih = Math.round((sakniki-isukMau)/240000)
    var actualTipuTipu = selisih-Math.round(Math.random()*9)
   // console.log({selisih,actualTipuTipu})
    settargetProductionData(selisih)
    setActualProductionData(actualTipuTipu)
    var tanggalHariIni = new Date()
      var waktuHungkul = tanggalHariIni.toLocaleTimeString()
      var hariHungkul = tanggalHariIni.getDate()
      var bulan = tanggalHariIni.getMonth()+1
      var tahun =  tanggalHariIni.getFullYear()
      var dinten = tanggalHariIni.getDay()
      var harihari = hariHungkul+"/"+bulan+"/"+tahun
      setCurrentTime(waktuHungkul.toString().split('.')[0]+":"+waktuHungkul.toString().split('.')[1])
      setCurrentDay(daysString[dinten]+","+" "+harihari)
    const timerDummy = setInterval(()=>{
      var tanggalHariIni = new Date()
      var waktuHungkul = tanggalHariIni.toLocaleTimeString()
      var hariHungkul = tanggalHariIni.toLocaleDateString()
      var dinten = tanggalHariIni.getDay()
      setCurrentTime(waktuHungkul.toString().split('.')[0]+":"+waktuHungkul.toString().split('.')[1])
      setCurrentDay(daysString[dinten]+","+" "+harihari)
    },30000)
    const timerData = setInterval(()=>{
      var sakniki = new Date()
      var isukMau = new Date()
      isukMau.setHours(0)
      isukMau.setMinutes(0)
      isukMau.setSeconds(0)
      isukMau.setMilliseconds(0)
      var selisih = Math.round((sakniki-isukMau)/30000)
      var actualTipuTipu = selisih-Math.round(Math.random()*(selisih*0.02))
     // console.log({selisih,actualTipuTipu})
      settargetProductionData(selisih)
      setActualProductionData(actualTipuTipu)
      // setActualProductionData(prev2=>{return prev2+Math.floor((Math.random())+2.05)})
      // settargetProductionData(prev=>{return prev+Math.floor((Math.random() * 1-0+1)+1.07)})
    },30000)
    return (()=>{clearInterval(timerDummy);clearInterval(timerData)})
  },[]);
    const [normalData, setNormalData] = useState(true);
    function handleNormal(){
      
      var ayeuna = new Date()
      var currentHours  = ayeuna.getHours()
      var jam = ("0" + currentHours ).slice(-2);
      var minutes = ayeuna.getMinutes()
      var menit = ("0" + minutes ).slice(-2);
      var tabuh = jam+':'+menit
      axios.post('http://172.16.0.21:38011/alert/resolve',{ sequence_name:"Productivty Dashboard",
      problem_detail:'Crank Shaft Line Stop at jam '+ tabuh,
      problem_id:"PD-001",
      action:"Genba to Line Crank Shaft"})
      .then(hsl=>{
        setNormalData(true);
      }
      )
      
    }
    function handleAbnormal(){
      var ayeuna = new Date()
      var currentHours  = ayeuna.getHours()
      var jam = ("0" + currentHours ).slice(-2);
      var minutes = ayeuna.getMinutes()
      var menit = ("0" + minutes ).slice(-2);
      var tabuh = jam+':'+menit

      console.log(tabuh)

      console.log("teu")
      axios.post('http://172.16.0.21:38011/alert/alert',{ sequence_name:"Productivty Dashboard",
      problem_detail:'Crank Shaft Line Stop at jam '+ tabuh ,
      problem_id:"PD-001",
      action:"Genba to Line Crank Shaft"})
      .then(hsl=>{
        setNormalData(false);
      }
      )
    }

    function handleResolveAll(){
      axios.post('http://172.16.0.21:38011/alert/resolveAll')
      console.log('coba')
    }

  // function Abnormal() {
  //   const [abnormal, setAbnormal] = useState(timerData=>true);

  // }

  const dataProductivity = [
    {
      name:'LPDC',
      isi:'37 MIN',
    },
    {
      name:'HPDC',
      isi:'10 MIN',
    },
    {
      name:'CAM SHAFT',
      isi:'3 MIN',
    },
    {
      name:'CYLINDER HEAD',
      isi:'40 MIN',
    },
    {
      name:'CYLINDER BLOCK',
      isi:'69 MIN',
    },
    {
      name:'ASSY LINE',
      isi:'7 MIN',
    },
  ]

  return (
  <ThemeContext.Provider value={{ themes, toggleTheme }} >
    <div className="App" id={themes}>
    <Box sx={{ display: 'flex'}}>
    <CssBaseline />
    {/* <AppBar position="fixed" open={open} style={{background:'linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 93.55%)'}}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" className='text-wrap'>
          PIDI 4.0
        </Typography>
      </Toolbar>
    </AppBar>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <div>
          <img src={pidiLogo} height='25' />
        </div>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {/* {['Normal', 'Abnormal'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <SentimentSatisfiedAltIcon /> : <WarningAmberIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))} */}
        {/* <div className="switch">
          <label> {themes === "light" ? "Light Mode" : "Dark Mode"}</label>
        <ReactSwitch onChange={toggleTheme} checked={themes === "dark"} />
        </div> */}
        {/* <Button variant="outlined" style={{margin:'10px'}}  onClick={()=>handleNormal()} >Normal</Button>
        <Button variant="outlined" onClick={()=>handleAbnormal()}>Abnormal</Button>
      </List> */}
      {/* <Divider />
    </Drawer>  */}
    {/* <Main open={open}> */}
      {/* <DrawerHeader /> */}
      
      <Grid container style={{padding:'1.5em', paddingTop:'0'}} spacing={3}>
        <Grid item xs={12} style={{textAlign:'center'}} >
        {/* <img src={pidiLogo} height='40'/> */}
        {/* <img src={pidiLogowhite} height='40'  onClick={()=>handleResolveAll()} /> */}
        </Grid>
        <Grid item xs={12}>
          <CustomCard className="bg-wrap" style={{padding:'1em', backgroundColor:"#0E1726"}}   >
            <Grid container>
              
              <Grid item xs={3} style={{textAlign:'center'}}>
                <Typography style={{fontSize:'25px'}} className='text-wrap'>
                 <b> Productivity Dashboard </b>
                </Typography>
              </Grid>
              <Grid item xs={5} style={{textAlign:'center'}}>
                <Typography variant='h4' className='text-wrap'>
                
                </Typography>
              </Grid>
              <Grid item xs={4} style={{textAlign:'center'}}>
                <Typography variant='h5' className='text-wrap'>
                {currentDay}
                </Typography>
              </Grid>
            </Grid>
          </CustomCard>
        </Grid>
        {/* ---------------------- */}

        <Grid spacing={3} container style={{padding:"1.3em"}}>
        <Grid item xs={6}>
                  <CustomCard className="bg-wrap" style={{padding:'1em'}}  onClick={()=>handleNormal()}>
                    <Grid container>
                      <Grid item xs={6} style={{textAlign:'center'}}>
                        <Typography variant='h5' className='text-wrap'>
                          CRANK SHAFT
                        </Typography>
                        <Grid container justifyContent="center" direction="column" alignItems="center" style={{height:"10em", padding:"0.5em"}}>
                          <CircularProgressbar style={{height:"1vh"}} text={(effieciencyRandomCok(actualProductionData,targetProductionData).randomEff).toString()+'%'} background backgroundPadding={6} value={effieciencyRandomCok(actualProductionData,targetProductionData).randomEff} styles={buildStyles({
                            pathColor: normalData? '#083449':'red',
                            backgroundColor:normalData? '#05c494': '#FAA0A0',
                            textColor:normalData ? 'white':'red',
                        })}/>
                      </Grid>
                      </Grid>
                      <Grid item xs={6} >
                      { normalData? <Grid item className='bg-wrap-1' style={{textAlign:'center', borderRadius:"1em", fontWeight:"700"}}>
                          <Typography variant='h5' className='text-wrap'>
                            RUNNING
                          </Typography>
                        </Grid>: <Grid item style={{textAlign:'center', backgroundColor:"red", borderRadius:"1em", fontWeight:"700"}}>
                          <Typography variant='h5' className='text-wrap'>
                            LINE STOP
                          </Typography>
                        </Grid>}
                        <Grid container item direction="column" justifyContent="center"
        alignItems="center" className='bg-wrapper' style={{textAlign:'center', height:"5em", borderRadius:"1em", fontWeight:"700", marginTop:"1.2em"}}>
                          <Typography variant='h5' className='text-wrap' style={{fontWeight:'800'}}>
                            MT-CALL
                          </Typography>
                          <Typography variant='h5' className='text-wrap'>
                          10
                          </Typography>
                        </Grid>
                        <Grid container justifyContent="center">
                        <Grid container justifyContent="space-between">
                          <Grid>
                            <Typography variant='h5' className='text-wrap'>
                            Actual Production: {effieciencyRandomCok(actualProductionData,targetProductionData).actualRandom}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography variant='h5' className='text-wrap'>
                            Target Production: {targetProductionData}
                            </Typography>
                          </Grid>
                        </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CustomCard>
                </Grid>
          {
            dataProductivity.map(dataProduct=>{
              return(
                <Grid item xs={6}>
                  <CustomCard className="bg-wrap" style={{padding:'1em'}} onClick={()=>handleAbnormal()}>
                    <Grid container>
                      <Grid item xs={6} style={{textAlign:'center'}}>
                        <Typography variant='h5' className='text-wrap'>
                          {dataProduct.name}
                        </Typography>
                        <Grid container justifyContent="center" direction="column" alignItems="center" style={{height:"10em", padding:"0.5em"}}>
                          <CircularProgressbar style={{height:"1vh"}} text={(effieciencyRandomCok(actualProductionData,targetProductionData).randomEff).toString()+'%'} background backgroundPadding={6} value={effieciencyRandomCok(actualProductionData,targetProductionData).randomEff} styles={buildStyles({
                            pathColor: effieciencyRandomCok(actualProductionData,targetProductionData).randomEff < efficiencyThreshold ? 'red':'#083449',
                            backgroundColor:effieciencyRandomCok(actualProductionData,targetProductionData).randomEff  < efficiencyThreshold ? '#FAA0A0':'#05c494',
                            textColor:effieciencyRandomCok(actualProductionData,targetProductionData).randomEff  < efficiencyThreshold ? 'red':'white',
                        })}/>
                      </Grid>
                      </Grid>
                      <Grid item xs={6} >
                     <Grid item className='bg-wrap-1' style={{textAlign:'center', borderRadius:"1em", fontWeight:"700"}}>
                          <Typography variant='h5' className='text-wrap'>
                            RUNNING
                          </Typography>
                        </Grid>
                        <Grid container item direction="column" justifyContent="center"
        alignItems="center" className='bg-wrapper' style={{textAlign:'center', height:"5em", borderRadius:"1em", fontWeight:"700", marginTop:"1.2em"}}>
                          <Typography variant='h5' className='text-wrap' style={{fontWeight:'800'}}>
                            MT-CALL
                          </Typography>
                          <Typography variant='h5' className='text-wrap'>
                          {dataProduct.isi}
                          </Typography>
                        </Grid>
                        <Grid container justifyContent="center">
                        <Grid container justifyContent="space-between">
                          <Grid>
                            <Typography variant='h5' className='text-wrap'>
                            Actual Production: {effieciencyRandomCok(actualProductionData,targetProductionData).actualRandom}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography variant='h5' className='text-wrap'>
                            Target Production: {targetProductionData}
                            </Typography>
                          </Grid>
                        </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CustomCard>
                </Grid>
              )
          })
          }
          <Grid item xs={6}>
            <CustomCard className="bg-wrap" style={{padding:'1em'}} onClick={()=>handleResolveAll()}>
                <Grid container Item xs={12} >
                <Grid item xs={3} >
                  <Typography style={{fontSize:'2vw'}} className='text-wrap'>LEGEND:</Typography>
                </Grid>
                <Grid item xs={3} container
                  direction="column"
                  justifyContent="center"
                  alignItems="center" >
                  {normalData ? <div id="button-blink" style={{width:'2vw',height:'2vw',backgroundColor:'rgb(84,207,32)',borderRadius:'8vw'}}/>:
                  <div id="button-noblink}" style={{width:'2vw',height:'2vw',backgroundColor:'rgb(84,207,32)',borderRadius:'8vw'}}></div>
                  }
                  <Typography style={{fontSize:'1vw'}} className='text-wrap'>Running</Typography>
                  </Grid>
                  <Grid Item xs={3} container
                  direction="column"
                  justifyContent="center"
                  alignItems="center">
                  <div style={{width:'2vw',height:'2vw',backgroundColor:'#EA7B15',borderRadius:'8vw'}}></div>
                  <Typography style={{fontSize:'1vw'}} className='text-wrap'>Stop (Under 30 Min)</Typography>
                  </Grid>
                  <Grid Item xs={3} container
                  direction="column"
                  justifyContent="center"
                  alignItems="center">
                  {normalData ? <div id="button-noblink" style={{width:'2vw',height:'2vw',backgroundColor:'rgb(246,90,91)',borderRadius:'8vw'}}/>:
                  <div id="button-blink" style={{width:'2vw',height:'2vw',backgroundColor:'#FE2929',borderRadius:'8vw'}}></div>
                }
                  <Typography style={{fontSize:'1vw'}} className='text-wrap'>Stop (Over 30 Min)</Typography>
                  </Grid>
                </Grid>
            </CustomCard>
          </Grid>
        </Grid>
        {/* ---------------------- */}
      </Grid>
    {/* </Main> */}
    </Box>
    </div>
  </ThemeContext.Provider>

  );
}

export default App;
